import { IColumnDef } from "@zetwerk/zet-list";
import { IFileUploadConfig } from '@zetwerk/zetui';
import { environment } from "src/environments/environment";

export const STATUS_MAP = {
  'APPROVAL_PENDING': 'Approval Pending',
  'PAYMENT_INITIATED': 'Payment Initiated',
  'PAYMENT_MADE': 'Payment Made',
  'CLOSED': 'Closed',
  'REJECTED': 'Rejected',
  'ON_HOLD' : 'On Hold'
}

export enum STATUS_ENUM {
    APPROVAL_PENDING = 'APPROVAL_PENDING',
    PAYMENT_INITIATED = 'PAYMENT_INITIATED',
    PAYMENT_MADE = 'PAYMENT_MADE',
    CLOSED = 'CLOSED',
    REJECTED = 'REJECTED',
    ON_HOLD = 'ON_HOLD'
}

export const PAYMENT_TICKET_APPROVER_ROLES_MAP = {
  'BIZ_FIN': 'Biz Finance',
  'PAYMENTS_TEAM': 'Payment Team',
  'VFS_TEAM': 'VFS Team',
  'BU_OPS': 'BU Ops',
  'HO_ADMIN': 'HO Admin'
};

export const ASSIGNED_TO_MAPPER = {
  'VFS_TEAM': 'VFS Team',
  'BIZ_FIN': 'Biz Finance Team',
  'PAYMENTS_TEAM': 'Payments Team',
  'BU_OPS': 'BU Ops',
  'HO_ADMIN': 'HO Admin'
};

export enum ASSIGNED_TO_MAPPER_ENUM {
    BIZ_FIN = 'BIZ_FIN',
    PAYMENTS_TEAM = 'PAYMENTS_TEAM',
    BU_OPS = 'BU_OPS',
    VFS_TEAM = 'VFS_TEAM',
    CSO = 'CSO'
}

export const LIST_TABS = [
  {
    title: 'Active Tickets',
    status: 'ACTIVE',
    name: 'active',
    countKey: 'totalActivePaymentTicketCount',
    active: true
  },
  {
    title: 'Closed Tickets',
    status: 'CLOSED',
    name: 'closed',
    countKey: 'totalClosedPaymentTicketCount',
    active: false
  }
];

export const COLUMN_DEFENITIONS: Array<IColumnDef> = [
  {
    field: 'paymentRequestNumber',
    headerName: 'Request ID / Date',
    cellClass: 'cell-center',
    cellRenderer: 'subtextDateCellRenderer',
    width: 180,
    valueGetter: (data) => {
      const res = {
        text: data.paymentTicketNumber || '',
        subText: data.createdAt || '',
        additionalSubText: (`, ${data.createdBy}` || ''),
      }
      return res;
    }
  },
  {
    field: 'docNumber',
    headerName: 'PR/Bill',
    cellClass: 'cell-center',
    cellRenderer: 'subtextCellRenderer',
    width: 180,
    valueGetter: (data) => {
      const res = {
        text: data.docNumber,
        subText: data.docType,
      }
      return res;
    }
  },
  {
    field: 'orderNumber',
    headerName: 'OD/Supplier',
    cellClass: 'cell-center',
    cellRenderer: 'subtextCellRenderer',
    width: 180,
    valueGetter: (data) => {
      const res = {
        text: data.orderNumber,
        subText: data.supplierName,
      }
      return res;
    }
  },
  {
    field: 'businessUnitName',
    headerName: 'BU',
    cellClass: 'cell-center',
    width: 120,
    valueGetter: (data) => {
      return (
        (data.businessUnitName) || ''
      )
    }
  },
  {
    field: 'requestedPaidAmount',
    headerName: 'Requested/Paid Amount',
    cellClass: 'cell-center',
    cellRenderer: 'subtextCurrencyCellRenderer',
    width: 120,
    valueGetter: (data) => {
      const res = {
        text: {
          currencySymbol: '',
          amount: ''
        },
        subText: {
          currencySymbol: '',
          amount: ''
        }
      };
      res['text']['currencySymbol'] = (data.symbol|| '&#8377;');
      res['text']['amount'] = (data.amountToBePaid || 0);

      res['subText']['currencySymbol'] = (data.symbol || '&#8377;');
      res['subText']['amount'] = (data.amountPaid || 0);
      return res;
    }
  },
  {
    field: 'approvedAmount',
    headerName: 'Approved Amount',
    cellClass: 'cell-center',
    cellRenderer: 'subtextCurrencyCellRenderer',
    width: 120,
    valueGetter: (data) => {
      const res = {
        text: {
          currencySymbol: '',
          amount: ''
        },
        subTextPara: ''
      };
      res['text']['currencySymbol'] = (data.symbol || '&#8377;');
      res['text']['amount'] = (data.approvedData.amount || 0);

      res['subTextPara'] = data.approvedData.paymentVia;
      return res;
    }
  },
  {
    field: 'assignedTo',
    headerName: 'Assigned To',
    cellClass: 'cell-center',
    cellRenderer: 'subtextCellRenderer',
    width: 180,
    valueGetter: (data) => {
      const res = {
        text: PAYMENT_TICKET_APPROVER_ROLES_MAP[data.assignedTo] || '',
        subText: data.updatedBy || '',
      }
      return res;
    }
  },
  {
    field: 'status',
    headerName: 'Status',
    cellClass: 'cell-center',
    cellRenderer: 'statusCellRenderer',
    width: 140,
    valueGetter: (data) => {
      const res = {
        status: '',
        statusMap: {}
      };
      res['status'] = data.status;
      res['statusMap'] = STATUS_MAP
      return res;
    }
  },
];

export const PAYMENT_VIA_MAPPER = {
  VFS: 'VFS',
  BANK: 'Bank',
}

/** File upload component config */
const API_ENDPOINT = environment.supplierMicroserviceAPIEndPoint;
export const fileUploadConfig: IFileUploadConfig = {
  acceptedFileTypes: ["jpeg", "pdf","png","jpg"],
  maxFiles: 1,
  maxSize: "10mb",
  signedUrlEndpoint: `${API_ENDPOINT}/files/signed-upload-urls`,
  registerFileEndpoint: `${API_ENDPOINT}/files/upload-files`,
};

export const PAYMENT_TICKET_DOC_TYPE = {
  BILL: 'bill',
  PR: 'paymentRequest'
}

